import { useCallback } from "react";
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  Button,
} from "@mui/material";
import { TripState } from "types/user";
import TripCommuteFrequency from "components/trips/TripCommuteFrequency";
import useNetworkTripCommuteFrequency from "hooks/trips/useNetworkTripCommuteFrequency";
import useDisplayMessage from "hooks/useDisplayMessage";
import { useMutation } from "@apollo/client";
import { DELETE_TRIP } from "graphql/queries";
import { removeTrip } from "redux/slices/shed/shedSlice";
import { useAppDispatch } from "redux/hooks";
import { CarState } from "types/cars";
import CarLogoBrand from "components/cars/CarLogoBrand";
import TripCardItems from "components/trips/TripCardItems";

type Props = {
  trip: TripState;
  tripCar: CarState;
};

const TripCard = ({ trip, tripCar }: Props) => {
  const { displayMessage } = useDisplayMessage();
  const [deleteTrip] = useMutation(DELETE_TRIP);

  const dispatch = useAppDispatch();

  const handleDeleteCar = useCallback(async () => {
    if (!trip.tripId) return;

    await deleteTrip({ variables: { tripId: trip.tripId } });

    dispatch(removeTrip(trip.tripId));
    displayMessage("Trip has been removed", "success");
  }, [trip.tripId, displayMessage, dispatch, deleteTrip]);

  const { commuteFrequency, increaseFrequency, decreaseFrequency } =
    useNetworkTripCommuteFrequency({
      trip,
    });

  return (
    <Card variant="outlined">
      <CardContent>
        <Box
          display="flex"
          pb={2}
          gap={0.5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography color="text.secondary"> License Plate:</Typography>
          <Chip label={trip.licensePlate} />
          <Box flexGrow={1} />
          <Button disabled={!tripCar} onClick={handleDeleteCar}>
            Delete
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Stack gap={0.5} pb={4}>
            <TripCardItems
              localCommuteFrequency={commuteFrequency}
              trip={trip}
              tripCar={tripCar}
            />
          </Stack>
          <CarLogoBrand size="full" carBrand={tripCar.carBrand} />
        </Box>
        <TripCommuteFrequency
          commuteFrequency={commuteFrequency}
          increaseFrequency={increaseFrequency}
          decreaseFrequency={decreaseFrequency}
        ></TripCommuteFrequency>
      </CardContent>
    </Card>
  );
};

export default TripCard;
