import { Typography, Chip } from "@mui/material";
import ParkIcon from "@mui/icons-material/Park";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { metersToKm } from "libraries/emission";
import { useAppSelector } from "redux/hooks";
import { selectReferenceTrip } from "redux/slices/shed/shedSlice";

type Props = {
  tripTreesCount: number;
  distanceMeters: number;
  totalKgEmission: number;
};

const TripEmissionDetails = ({
  tripTreesCount,
  distanceMeters,
  totalKgEmission,
}: Props) => {
  const referenceTrip = useAppSelector(selectReferenceTrip);

  const tripPlural = (count: number) => (count === 1 ? "trip" : "trips");

  const tripKM = `${Math.round(
    metersToKm(distanceMeters * referenceTrip.commuteFrequency)
  )} KM`;
  const tripCO2 = `${(totalKgEmission * referenceTrip.commuteFrequency).toFixed(
    1
  )} KG CO2`;

  return (
    <Typography variant="body2">
      <Chip
        size="small"
        sx={{ mx: 0.25, alignItems: "flex-end" }}
        icon={
          <DirectionsCarIcon fontSize="small" sx={{ alignSelf: "center" }} />
        }
        label={referenceTrip.commuteFrequency}
      />
      {tripPlural(referenceTrip.commuteFrequency)} ({tripKM}) emit {tripCO2},
      being the same as what
      <Chip
        size="small"
        sx={{ mx: 0.25, alignItems: "flex-end" }}
        icon={<ParkIcon fontSize="small" sx={{ alignSelf: "center" }} />}
        label={(tripTreesCount * referenceTrip.commuteFrequency).toFixed(2)}
      />
      rainforest trees absorb yearly
    </Typography>
  );
};

export default TripEmissionDetails;
