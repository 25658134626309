import { useCallback } from "react";
import { UNSET_DONATION_SUGGESTION } from "libraries/donation";
import { SelectChangeEvent } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAppSelector } from "redux/hooks";
import { selectReferenceTrip } from "redux/slices/shed/shedSlice";
import { tripCO2ToCostCalculation } from "libraries/donation";

type Props = {
  donationSuggestion: number;
  setSelectedAmount: (amount: number) => void;
  selectedAmount: number | null;
  totalKgEmission: number;
};

const CarDonationSelect = ({
  donationSuggestion,
  setSelectedAmount,
  selectedAmount,
  totalKgEmission,
}: Props) => {
  const referenceTrip = useAppSelector(selectReferenceTrip);

  const tripDonationSugestion = () => {
    return parseFloat(donationSuggestion.toFixed(2));
  };

  const handleDonationChange = useCallback(
    (event: SelectChangeEvent<number>) =>
      setSelectedAmount(Number(event.target.value)),
    [setSelectedAmount]
  );

  return (
    <FormControl sx={{ display: "flex", justifyContent: "center" }}>
      <InputLabel id="donation-input">Donate</InputLabel>
      <Select
        labelId="donation-simple-select-label"
        id="donation-simple-select-label"
        label="Donate"
        value={
          selectedAmount !== null ? parseFloat(selectedAmount.toFixed(2)) : ""
        }
        onChange={handleDonationChange}
      >
        {donationSuggestion !== UNSET_DONATION_SUGGESTION && (
          <MenuItem value={tripDonationSugestion()}>
            {tripDonationSugestion()} kr (All {referenceTrip.commuteFrequency}{" "}
            trips )
            {(totalKgEmission * referenceTrip.commuteFrequency).toFixed(1)} KG
            CO2
          </MenuItem>
        )}

        <MenuItem value={tripCO2ToCostCalculation(totalKgEmission, 1)}>
          {tripCO2ToCostCalculation(totalKgEmission, 1)}kr (1 trip){" "}
          {(totalKgEmission * 1).toFixed(1)} kg CO2
        </MenuItem>
        <MenuItem value={tripCO2ToCostCalculation(totalKgEmission, 2)}>
          {tripCO2ToCostCalculation(totalKgEmission, 2)} kr (2 trips){" "}
          {(totalKgEmission * 2).toFixed(1)} kg CO2
        </MenuItem>
        <MenuItem value={tripCO2ToCostCalculation(totalKgEmission, 3)}>
          {tripCO2ToCostCalculation(totalKgEmission, 3)} kr (3 trips ){" "}
          {(totalKgEmission * 3).toFixed(1)} kg CO2
        </MenuItem>
        <MenuItem value={tripCO2ToCostCalculation(totalKgEmission, 5)}>
          {tripCO2ToCostCalculation(totalKgEmission, 5)} kr(5 trips){" "}
          {(totalKgEmission * 5).toFixed(1)} kg CO2
        </MenuItem>
        <MenuItem value={tripCO2ToCostCalculation(totalKgEmission, 10)}>
          {tripCO2ToCostCalculation(totalKgEmission, 10)} kr(10 trips){" "}
          {(totalKgEmission * 10).toFixed(1)} kg CO2
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default CarDonationSelect;
