import React from "react";
import { Paper, Box, styled } from "@mui/material";
import ParkIcon from "@mui/icons-material/Park";
import { times } from "lodash";

type Props = {
  treeCount: number;
  donated: number;
  leftToDonate: number;
};

const GreenTreeIcon = styled(ParkIcon)`
  fill: seagreen;
`;

const DisabledTreeIcon = styled(ParkIcon)(({ theme }) => ({
  fill: theme.palette.text.disabled,
}));

const treeMaskWidth = (count: number): number => (count < 0.3 ? 0.4 : count);

const treePolygonMask = (count: number): string =>
  `polygon(0 0, ${treeMaskWidth(count) * 100}% 0, ${
    treeMaskWidth(count) * 100
  }% 100%, 0 100%)`;

const ForestTree = ({ treeCount, donated, leftToDonate }: Props) => (
  <Box
    flexWrap="wrap"
    display="flex"
    justifyContent="center"
    py={2}
    style={{ maxWidth: window.innerWidth }}
  >
    <Paper
      elevation={2}
      sx={{
        p: { xs: 0.5, sm: 2 },
        borderRadius: 2,
        width: window.innerWidth,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {
        <Box>
          {times(treeCount).map((index: number) =>
            index / treeCount >= donated / leftToDonate ? (
              <DisabledTreeIcon fontSize="medium" key={index} />
            ) : (
              <GreenTreeIcon fontSize="medium" key={index} />
            )
          )}
          {/*  if not 100 percent is paid down, show a grey tree at the end*/}
          {donated / leftToDonate < 1 ? (
            <DisabledTreeIcon
              style={{
                clipPath: treePolygonMask(treeCount - Math.floor(treeCount)),
                fontSize: "medium",
              }}
            />
          ) : (
            <GreenTreeIcon
              style={{
                clipPath: treePolygonMask(treeCount - Math.floor(treeCount)),
                fontSize: "medium",
              }}
            />
          )}
        </Box>
      }
    </Paper>
  </Box>
);

export default ForestTree;
