import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

type Props = {
  handleSubmit: (event: React.SyntheticEvent) => Promise<void>;
  loading: boolean;
};

const SubmitButton = ({ handleSubmit, loading }: Props) => {
  return (
    <Box>
      <LoadingButton
        size="large"
        variant="contained"
        color="secondary"
        type="submit"
        loading={loading}
        onClick={handleSubmit}
      >
        Submit
      </LoadingButton>
    </Box>
  );
};

export default SubmitButton;
