import { CarState } from "../types/cars";

export const getNeutralCar = (): CarState => ({
  id: "",
  licensePlate: "",
  emissionCO2: 0,
  emissionType: "",
  carBrand: "",
  nextControl: "",
  previousControl: "",
  carModel: "",
});
