import { Button } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { TripState } from "types/user";
import useShareTrip from "hooks/trips/useShareTrip";

type Props = {
  trip: TripState;
  licensePlate: string;
};

const ShareTripButton = ({ trip, licensePlate }: Props) => {
  const { handleShareTrip } = useShareTrip({
    trip,
    carLicensePlate: licensePlate,
  });

  return (
    <Button startIcon={<ShareIcon />} onClick={handleShareTrip}>
      Share
    </Button>
  );
};

export default ShareTripButton;
