import React from "react";

import { Box, styled } from "@mui/material";

type Props = {
  licensePlate: string | undefined;
  carBrand: string;
  carModel: string;
  nextControl?: string;
};

const StyledImg = styled("img")`
  margin-left: "auto";
  margin-right: "auto";
  width: 15vw;
  height: 15vw;
  max-width: 100px;
  max-height: 100px;
`;

const StyledText = styled("div")`
  margin: 6px;
  text-align: center;
  font-weight: 500;
`;

const CarDetailsHeader = ({ carBrand, carModel, licensePlate }: Props) => (
  <>
    <Box textAlign="center" maxWidth="100">
      <StyledImg
        alt="brand-logo"
        src={process.env.PUBLIC_URL + `/brands/${carBrand}.svg`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = process.env.PUBLIC_URL + `/brands/car.svg`;
        }}
      />
    </Box>
    <StyledText>
      {carBrand.toUpperCase()} {carModel.toUpperCase()}
    </StyledText>
    <StyledText>{licensePlate}</StyledText>
  </>
);

export default CarDetailsHeader;
