import React, { useState, useCallback } from "react";
import {
  Stack,
  Box,
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EmailIcon from "@mui/icons-material/Email";
import { Password } from "@mui/icons-material";

import { AppContainer } from "../base-app/App";
import AppTopBar from "components/base-app/AppTopBar";
import SubmitButton from "components/login/SubmitButton";
import { ROUTE } from "components/base-app/AppRoutes";
import { ADD_NEW_USER } from "graphql/queries";
import { useMutation } from "@apollo/client";
import useDisplayMessage from "hooks/useDisplayMessage";

import useAppNavigate from "hooks/useAppNavigate";

const CreateNewUserPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigateTo = useAppNavigate();
  const { displayMessage } = useDisplayMessage();

  const [addUser] = useMutation(ADD_NEW_USER, {
    variables: { username: email, password },
  });

  const handleSubmit = async () => {
    if (!email.endsWith("@rainforest.no")) {
      console.log("happens");
      displayMessage(
        "We are in testing mode, only emails with @rainforest.no can create users",
        "error"
      );
      return;
    } else {
      const { data } = await addUser();

      displayMessage(data.addUser, "success");
      navigateTo(ROUTE.MAP);
    }
  };

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setEmail(event.target.value),
    []
  );

  const handlePasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setPassword(event.target.value),
    []
  );

  return (
    <>
      <AppTopBar icon={<VpnKeyIcon color="primary" />} title="Sign in" />
      <AppContainer>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ mt: 2 }}
          component="form"
        >
          <Typography variant="h5" my={3} mr={2} ml={2} textAlign={"center"}>
            {"Create a new user"}
          </Typography>

          <Typography variant="subtitle1" mr={2} ml={2} textAlign={"center"}>
            {
              "Please be aware that only @rainforest.no accounts are currently allowed"
            }
          </Typography>
          <Box display="flex" alignContent="center" mt={2}></Box>
          <Stack gap={2} mb={2}>
            <FormControl variant="outlined">
              <OutlinedInput
                id="email-input"
                value={email}
                onChange={handleEmailChange}
                endAdornment={
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                }
                aria-describedby="email-input"
                inputProps={{
                  "aria-label": "email",
                  type: "email",
                }}
              />
              <FormHelperText id="email-input">Your Email</FormHelperText>
            </FormControl>
            <FormControl variant="outlined">
              <OutlinedInput
                id="password-input"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="start">
                    <Password />
                  </InputAdornment>
                }
                aria-describedby="password-input"
              />
              <FormHelperText id="password-input">Password</FormHelperText>
            </FormControl>
          </Stack>
          <SubmitButton
            handleSubmit={handleSubmit}
            loading={false}
          ></SubmitButton>
        </Grid>
      </AppContainer>
    </>
  );
};

export default CreateNewUserPage;
