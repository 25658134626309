import { useCallback } from "react";
import { TripState } from "types/user";
import { forEach } from "lodash/fp";
import { tripStateToTripShare } from "libraries/trip";
import useDisplayMessage from "hooks/useDisplayMessage";
import { ROUTE } from "components/base-app/AppRoutes";

type Props = {
  trip: TripState;
  carLicensePlate: string;
};

type ReturnType = {
  handleShareTrip: () => void;
};

const useShareTrip = ({ trip, carLicensePlate }: Props): ReturnType => {
  const { displayMessage } = useDisplayMessage();

  const handleShareTrip = useCallback(async () => {
    // Serialize TripShare data in URL
    const tripShare = tripStateToTripShare(trip, carLicensePlate);

    const shareParams = new URLSearchParams();

    const serializeEntry = ([key, value]: string[]) =>
      shareParams.append(key, value);

    forEach(serializeEntry)(Object.entries(tripShare));

    const shareURL = new URL(window.location.origin + ROUTE.MAP);
    shareURL.search = shareParams.toString();

    if (!window.navigator.share) {
      displayMessage("Share feature not available", "warning");
    } else {
      try {
        await window.navigator.share({
          text: `From ${tripShare.fromAddress} to ${tripShare.toAddress}`,
          title: "Trip itinerary on Shed",
          url: shareURL.toString(),
        });
      } catch {
        // Share cancelled
      }
    }
  }, [displayMessage, trip, carLicensePlate]);

  return { handleShareTrip };
};

export default useShareTrip;
