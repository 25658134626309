import { useCallback, useState } from "react";
import { styled, Box, Paper, Stack, Button } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import MapLocationField from "components/map/MapLocationField";
import { MapLocation } from "types/map";
import { useAppDispatch } from "../../redux/hooks";
import useCarSearch from "hooks/useCarSearch";
import CarBrandLoader from "components/cars/CarBrandLoader";
import CarSelectReference from "components/cars/CarSelectReference";
import {
  registerReferenceTripLocation,
  clearItinerary,
  clearTrip,
} from "redux/slices/shed/shedSlice";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MapTripDetails from "./MapTripDetails";

const PANEL_WIDTH_MOBILE = 250;
const PANEL_WIDTH_DEFAULT = 400;

const SearchToolbar = styled(Box)({
  display: "flex",
  width: "100%",
});

const PaperPanel = styled(Paper)(({ theme }) => ({
  display: "flex",
  top: 0,
  position: "fixed",
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  padding: theme.spacing(1),
  borderRadius: 16,
  maxHeight: "80%",
  overflowY: "auto",
}));

type Props = {
  locationFrom: MapLocation;
  locationTo: MapLocation;
};

const MapPanelSearch = ({ locationFrom, locationTo }: Props) => {
  const [licensePlate, setLicensePlate] = useState("");
  const displayPlate = licensePlate;

  const { smoothLoading, handleSearchCar } = useCarSearch();

  const [isMinimized, setIsMinimized] = useState(false);

  const dispatch = useAppDispatch();

  const handleLocationFromChange = useCallback(
    (newFromLocation: MapLocation) =>
      dispatch(
        registerReferenceTripLocation({
          fromLatitude: newFromLocation.latitude ?? 0,
          fromLongitude: newFromLocation.longitude ?? 0,
          fromAddress: newFromLocation.formattedAddress ?? "",
        })
      ),
    [dispatch]
  );

  const handleLocationToChange = useCallback(
    (newToLocation: MapLocation) =>
      dispatch(
        registerReferenceTripLocation({
          toLatitude: newToLocation.latitude ?? undefined,
          toLongitude: newToLocation.longitude ?? undefined,
          toAddress: newToLocation.formattedAddress ?? undefined,
        })
      ),
    [dispatch]
  );

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClearTrip = () => {
    dispatch(clearTrip());
    dispatch(clearItinerary());
  };

  return (
    <PaperPanel
      elevation={3}
      sx={{ width: { xs: PANEL_WIDTH_MOBILE, sm: PANEL_WIDTH_DEFAULT } }}
    >
      {isMinimized && (
        <Button
          sx={{ mt: 1, mb: 2, justifyContent: "start", display: "flex" }}
          onClick={toggleMinimize}
        >
          <ArrowForwardIosIcon /> Show
        </Button>
      )}
      {!isMinimized && (
        <Stack width="100%">
          <SearchToolbar>
            <Stack width="100%" mt={1}>
              <Box display="flex" justifyContent={"space-between"}>
                <Button startIcon={<MapIcon />} onClick={handleClearTrip}>
                  New Trip
                </Button>
                <Button onClick={toggleMinimize}>
                  <ArrowBackIosIcon /> Hide
                </Button>
              </Box>
              <CarSelectReference
                size="small"
                onSearchCar={handleSearchCar}
                onInputChange={setLicensePlate}
                inputValue={displayPlate}
              />
              <CarBrandLoader loading={smoothLoading} />
              <MapLocationField
                label="From"
                location={locationFrom}
                onChange={handleLocationFromChange}
              />
              <MapLocationField
                label="To"
                location={locationTo}
                onChange={handleLocationToChange}
              />
            </Stack>
          </SearchToolbar>
          <MapTripDetails />
        </Stack>
      )}
    </PaperPanel>
  );
};

export default MapPanelSearch;
