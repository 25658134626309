import React, { useState, useCallback } from "react";
import {
  Stack,
  Box,
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Button,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EmailIcon from "@mui/icons-material/Email";
import { Password } from "@mui/icons-material";

import { AppContainer } from "../base-app/App";
import AppTopBar from "../base-app/AppTopBar";
import SubmitButton from "../login/SubmitButton";
import { ROUTE } from "../base-app/AppRoutes";
import { useLoginWithEmailAndPassword } from "../../hooks/useLoginWithEmailAndPassword";
import { useAppSelector } from "../../redux/hooks";
import {
  selectReferenceCar,
  selectReferenceItinerary,
} from "../../redux/slices/shed/shedSlice";
import useAppNavigate from "hooks/useAppNavigate";
import useDisplayMessage from "hooks/useDisplayMessage";
import { getAuthAlertMessage } from "libraries/auth";

const LoginEmailAndPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigateTo = useAppNavigate();
  const { displayMessage } = useDisplayMessage();

  const { loading, handleLoginEmailAndPassword } = useLoginWithEmailAndPassword(
    {
      email,
      password,
      successNavigation: ROUTE.MAP,
    }
  );

  const car = useAppSelector(selectReferenceCar);
  const itinerary = useAppSelector(selectReferenceItinerary);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    // Check if email is a confirmation link
    const CONFIRMATION_LINK_REGEXP = /^https:\/\/.*.firebaseapp.com/;

    if (CONFIRMATION_LINK_REGEXP.test(email)) {
      window.location.href = email;
    } else {
      const loginCode = await handleLoginEmailAndPassword();

      if (loginCode) {
        const userMessage = getAuthAlertMessage(loginCode);

        displayMessage(userMessage.message, userMessage.type);
      }
    }
  };

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setEmail(event.target.value),
    []
  );

  const handlePasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setPassword(event.target.value),
    []
  );

  return (
    <>
      <AppTopBar icon={<VpnKeyIcon color="primary" />} title="Sign in" />
      <AppContainer>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ mt: 2 }}
          component="form"
        >
          <Typography variant="h5" my={3} mr={2} ml={2} textAlign={"center"}>
            {"Empowers individuals to act on climate change"}
          </Typography>

          <Typography variant="subtitle1" mr={2} ml={2} textAlign={"center"}>
            {"We are currently testing and will release in the upcoming months"}
          </Typography>
          <Box display="flex" alignContent="center">
            <Typography my={3} mr={2} ml={2} textAlign={"center"}>
              {car && itinerary
                ? "Log In to save your trips and do donations"
                : "Enter your email that ends with @rainforest.no "}
            </Typography>
          </Box>
          <Stack gap={2} mb={2}>
            <FormControl variant="outlined">
              <OutlinedInput
                id="email-input"
                value={email}
                onChange={handleEmailChange}
                endAdornment={
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                }
                aria-describedby="email-input"
                inputProps={{
                  "aria-label": "email",
                  type: "email",
                }}
              />
              <FormHelperText id="email-input">Your Email</FormHelperText>
            </FormControl>
            <FormControl variant="outlined">
              <OutlinedInput
                id="password-input"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="start">
                    <Password />
                  </InputAdornment>
                }
                aria-describedby="password-input"
              />
              <FormHelperText id="password-input">Password</FormHelperText>
            </FormControl>
          </Stack>
          <Stack mb={2}>
            <SubmitButton
              handleSubmit={handleSubmit}
              loading={loading}
            ></SubmitButton>
          </Stack>
          <Button onClick={() => navigateTo(ROUTE.FORGET_PASSWORD)}>
            Reset password
          </Button>
          <Button onClick={() => navigateTo(ROUTE.NEW_USER)}>
            Create user
          </Button>
        </Grid>
      </AppContainer>
    </>
  );
};

export default LoginEmailAndPasswordPage;
