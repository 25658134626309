import { SyntheticEvent, useCallback } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Typography,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { capitalize } from "lodash/fp";
import { useSelector } from "react-redux";
import { CarState } from "../../types/cars";
import CarLogoBrand from "./CarLogoBrand";
import { selectMyCars } from "../../redux/slices/shed/shedSlice";
import { useAppDispatch } from "../../redux/hooks";
import { registerReferenceCar } from "../../redux/slices/shed/shedSlice";
import { omit } from "lodash";

type Props = {
  size: "small";
  inputValue: string;
  onSearchCar: (licensePlate: string) => void;
  onInputChange: (newInputValue: string) => void;
};

type CarStateOption = Partial<CarState> & {
  inputValue?: string;
};

const filter = createFilterOptions<CarStateOption>();

const CarSelectReference = ({
  onSearchCar,
  size,
  inputValue,
  onInputChange,
}: Props) => {
  const dispatch = useAppDispatch();
  const myCars = useSelector(selectMyCars);

  const handleSelection = useCallback(
    (event: SyntheticEvent, selectedCar: string | CarStateOption | null) => {
      if (typeof selectedCar === "string") {
      } else if (selectedCar && selectedCar.inputValue) {
        onSearchCar(selectedCar.inputValue);
      } else if (selectedCar && typeof selectedCar === "object") {
        selectedCar = omit(selectedCar, ["__typename"]);

        dispatch(registerReferenceCar(selectedCar as CarState));
      }
    },
    [dispatch, onSearchCar]
  );

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const hasCar = myCars.some(
      (car) => car.licensePlate === event.target.value
    );

    !hasCar && onSearchCar(event.target.value);
  };

  return (
    <Box display="flex" flexWrap="wrap" mb={1.5}>
      <FormControl fullWidth variant="standard">
        <Autocomplete
          id="car-reference-selector"
          options={myCars}
          onChange={handleSelection}
          onBlur={handleBlur}
          getOptionLabel={(car: CarStateOption) => ""}
          renderOption={(
            renderProps,
            { carBrand, carModel, licensePlate }: CarStateOption
          ) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...renderProps}
            >
              <CarLogoBrand size="small" carBrand={carBrand ?? ""} />
              {capitalize(carBrand ?? "")} {carModel}
              {licensePlate && (
                <Typography ml={0.5} variant="caption" color="text.secondary">
                  • {licensePlate}
                </Typography>
              )}
            </Box>
          )}
          filterOptions={(options: CarStateOption[], params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.licensePlate
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                carBrand: `Search with License Plate "${inputValue}"`,
              });
            }
            return filtered;
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => onInputChange(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Plate number"
              size={size}
              autoComplete="off"
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default CarSelectReference;
