import { useState, useCallback } from "react";
import { omit, debounce } from "lodash"; // Assuming you're using lodash for omit and debounce
import { SEARCH_CAR } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { CarState } from "../types/cars";
import { registerReferenceCar } from "../redux/slices/shed/shedSlice";
import { useAppDispatch } from "../redux/hooks";

type ReturnType = {
  handleSearchCar: (licensePlate: string) => void;
  smoothLoading: boolean;
  queryLoading: boolean;
};

type CarQuery = {
  searchCar: CarState | null;
};

const useCarSearch = (): ReturnType => {
  const dispatch = useAppDispatch();
  const [smoothLoading, setSmoothLoading] = useState<boolean>(false);

  const onQueryCompleted = useCallback(
    ({ searchCar }: CarQuery) => {
      if (searchCar) {
        const currentCar = omit(searchCar, ["__typename"]) as CarState;

        dispatch(registerReferenceCar(currentCar));
      } else {
        console.log("No Car Exist");
      }

      const debouncedSetLoading = debounce(setSmoothLoading, 500);
      debouncedSetLoading(false);
    },
    [dispatch]
  );

  const [searchCar, { loading: queryLoading }] = useLazyQuery<CarQuery>(
    SEARCH_CAR,
    {
      fetchPolicy: "network-only",
      onCompleted: onQueryCompleted,
    }
  );

  const handleSearchCar = useCallback(
    async (licensePlate: string) => {
      licensePlate && setSmoothLoading(true);

      licensePlate && (await searchCar({ variables: { licensePlate } }));
    },
    [setSmoothLoading, searchCar]
  );

  return { handleSearchCar, smoothLoading, queryLoading };
};

export default useCarSearch;
