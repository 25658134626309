import { useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import PhoneNumberInput from "components/payment/PhoneNumberInput";
import useHandleVippsPayment from "hooks/useHandleVippsPayment";
import VippsButton from "components/payment/VippsButton";
import useDisplayMessage from "hooks/useDisplayMessage";
import { TripState } from "types/user";
import { CarState } from "types/cars";
import { tripEmission } from "libraries/emission";
import { tripCO2ToCostCalculation } from "libraries/donation";
import CarDonationSelect from "components/cars/CarDonationSelect";
import TripEmissionDetails from "components/trips/TripEmissionDetails";
import { emissionToTrees } from "libraries/emission";

type Props = {
  trip: TripState;
  tripCar: CarState;
};

const CarDonation = ({ trip, tripCar }: Props) => {
  const { displayMessage } = useDisplayMessage();
  const [isMobileNumberError, setMobileNumberError] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);

  const { distanceMeters, totalKgEmission } = tripEmission(
    tripCar,
    trip.distanceMeters
  );

  const donationSuggestion = tripCO2ToCostCalculation(
    totalKgEmission,
    trip.commuteFrequency
  );

  const tripTreesCount = emissionToTrees({
    distanceMeters: trip.distanceMeters,
    emissionType: tripCar?.emissionType ?? "",
    gramEmission: tripCar?.emissionCO2 ?? 0,
  });

  const { handleVippsPayment, loadingPayment } = useHandleVippsPayment({
    co2FootprintInKg: totalKgEmission * trip.commuteFrequency,
    donation: selectedAmount ?? donationSuggestion,
    distance: distanceMeters,
    mobileNumber: mobileNumber,
  });

  const payWithVipps = useCallback(() => {
    const isValidPhoneNumber = /^\d{8}$/.test(mobileNumber);

    !isValidPhoneNumber &&
      displayMessage("Add a valid phone number to donate", "info");
    setMobileNumberError(!isValidPhoneNumber);

    isValidPhoneNumber && handleVippsPayment(trip.tripId ?? "", tripCar);
  }, [trip, handleVippsPayment, displayMessage, mobileNumber, tripCar]);

  return (
    <Box display="flex" flexDirection="column" p={3} gap={2}>
      <Typography variant="body1" textAlign="center">
        Become a Rainforest Guardian
      </Typography>

      <TripEmissionDetails
        tripTreesCount={tripTreesCount}
        distanceMeters={trip.distanceMeters}
        totalKgEmission={totalKgEmission}
      ></TripEmissionDetails>

      <CarDonationSelect
        donationSuggestion={donationSuggestion}
        setSelectedAmount={setSelectedAmount}
        selectedAmount={selectedAmount}
        totalKgEmission={totalKgEmission}
      />

      <PhoneNumberInput
        mobileNumber={mobileNumber}
        isMobileNumberError={isMobileNumberError}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setMobileNumber(event.target.value)
        }
      />

      <VippsButton onClick={payWithVipps} loading={loadingPayment} />
      <Typography variant="body2">
        For every 25 KG CO2 (31,25 kroner) you donate, you save a tree in the
        Rainforest for one year.
      </Typography>

      <Typography variant="body2">
        By supporting the Rainforest Foundation, you directly contribute to
        global emission reductions and biodiversity.
      </Typography>
    </Box>
  );
};

export default CarDonation;
