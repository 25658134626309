import { Box, Collapse } from "@mui/material";
import ForestTree from "components/core/ForestTree";
import SaveTripButton from "components/cars/SaveTripButton";
import ShareTripButton from "components/cars/ShareTripButton";
import { useAppSelector } from "redux/hooks";
import {
  selectReferenceItinerary,
  selectReferenceTrip,
  selectReferenceCar,
  selectHasReferenceTrip,
} from "redux/slices/shed/shedSlice";
import { emissionToTrees } from "libraries/emission";
import { styled } from "@mui/material";
import TripCommuteFrequency from "components/trips/TripCommuteFrequency";
import useReferenceTripCommuteFrequency from "hooks/trips/useReferenceTripCommuteFrequency";
import TripEmissionDetails from "components/trips/TripEmissionDetails";
import { tripEmission } from "libraries/emission";

const PANEL_DISPLAY_HEIGHT = 500;

const PanelDisplay = styled(Box)(({ theme }) => ({
  maxHeight: PANEL_DISPLAY_HEIGHT,
  overflow: "hidden",
  kscroll: "auto",
  padding: theme.spacing(1),
}));

const MapTripDetails = () => {
  const itinerary = useAppSelector(selectReferenceItinerary);
  const trip = useAppSelector(selectReferenceTrip);
  const itineraryMeters = itinerary?.distanceMeters ?? 0;
  const referenceCar = useAppSelector(selectReferenceCar);
  const hasTrip = useAppSelector(selectHasReferenceTrip);
  const car = useAppSelector(selectReferenceCar);

  const { commuteFrequency, increaseFrequency, decreaseFrequency } =
    useReferenceTripCommuteFrequency();

  const { totalKgEmission } = tripEmission(
    referenceCar,
    itinerary?.distanceMeters ?? 0
  );

  const tripTreesCount = emissionToTrees({
    distanceMeters: itineraryMeters,
    emissionType: car?.emissionType ?? "",
    gramEmission: car?.emissionCO2 ?? 0,
  });

  return (
    <Collapse in={hasTrip}>
      <PanelDisplay mt={2} display="flex" flexDirection="column" gap={2}>
        <TripCommuteFrequency
          commuteFrequency={commuteFrequency}
          increaseFrequency={increaseFrequency}
          decreaseFrequency={decreaseFrequency}
        />
        <TripEmissionDetails
          tripTreesCount={tripTreesCount}
          distanceMeters={itinerary?.distanceMeters ?? 0}
          totalKgEmission={totalKgEmission}
        />

        <ForestTree
          treeCount={tripTreesCount * commuteFrequency}
          donated={0}
          leftToDonate={0}
        />

        <Box display="flex" gap={1}>
          <SaveTripButton itinerary={itinerary} />
          <Box flexGrow={1} />
          <ShareTripButton
            trip={trip}
            licensePlate={referenceCar?.licensePlate ?? ""}
          />
        </Box>
      </PanelDisplay>
    </Collapse>
  );
};
export default MapTripDetails;
